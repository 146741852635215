import React from 'react';

const ViewModeIcon = () =>(
    <svg className={'old-svg'} width="45px" height="45px" viewBox="0 0 45 45" version="1.1">
        <g id="icon_viewmode" stroke="none" fill="none">
            <path d="M26.2573987,35.907336 L7.56479615,35.907336 C7.25280897,35.907336 7.00003974,35.6545668 7.00003974,35.3425796 L7.00003974,17.6932463 C7.00003974,17.3812591 7.25280897,17.1284899 7.56479615,17.1284899 L26.2573987,17.1284899 C26.5691872,17.1284899 26.8221551,17.3812591 26.8221551,17.6932463 L26.8221551,35.3425796 C26.8221551,35.6545668 26.5691872,35.907336 26.2573987,35.907336 Z M10.1786923,32.9265668 L23.7419872,32.9265668 L23.7419872,23.9842591 L10.1786923,23.9842591 L10.1786923,32.9265668 Z M20.5011955,14.8968078 L18.3912083,14.9138976 C18.1223429,14.9160835 17.9023622,14.7000771 17.8997788,14.4312117 L17.8600353,10.5160706 C17.8572532,10.2452181 18.0758429,10.024045 18.3468942,10.0236476 L37.4601827,10.0000001 C37.7304391,9.99980142 37.9494263,10.2193848 37.9484327,10.4894424 L37.8776891,28.4155899 C37.8766955,28.684654 37.6579071,28.9018527 37.3888429,28.9012565 L29.3451378,28.8790001 C29.0764712,28.8782053 28.858875,28.6602117 28.858875,28.391545 L28.858875,26.6038783 C28.858875,26.3362053 29.0748814,26.1186091 29.3423558,26.1164232 L34.3848237,26.0748912 C34.6532917,26.0725065 34.8692981,25.8539168 34.8683045,25.5854489 L34.8224006,13.4916732 C34.8214071,13.2228078 34.6028173,13.0054104 34.3339519,13.0060065 L21.4713365,13.0290578 C21.2024712,13.0294553 20.9846763,13.2476476 20.9846763,13.516513 L20.9846763,14.4093527 C20.9846763,14.6770258 20.7688686,14.8946219 20.5011955,14.8968078 Z" id="Fill-1" fill="#0F9DDD"/>
        </g>
    </svg>
);

export default ViewModeIcon