import React from 'react';

const WireFrameRenderIcon = () =>(
    <svg width="37px" height="37px" viewBox="0 0 37 37" version="1.1">
        <g id="icon-wireframe" stroke="none" fill="none">
            <path d="M4.68555133,13.8830565 L16.1425856,20.6830565 L16.1072243,33.1083056 L4.74448669,26.5906977 L4.68555133,13.8830565 Z M3,11.0026578 L3.08250951,27.4943522 L17.7809886,35.9322259 L17.8163498,19.7906977 L3,11.0026578 Z" id="fill-3" fill="#F59EB5"/>
            <path d="M32.2201521,13.9056478 L32.1022814,26.6584718 L20.7513308,33.1760797 L20.786692,20.7395349 L32.2201521,13.9056478 Z M33.9174905,11.0026578 L19.1129278,19.8471761 L19.0775665,36 L33.7642586,27.5734219 L33.9174905,11.0026578 Z" id="fill-2" fill="#F59EB5" />
            <path d="M18.5235741,3.9089701 L29.9806084,10.0651163 L18.4057034,17.0232558 L6.80722433,9.98604651 L18.5235741,3.9089701 Z M18.5353612,2.07906977 L3.51863118,9.8730897 L18.4057034,18.9096346 L33.2574144,9.98604651 L18.5353612,2.07906977 Z" id="fill-1" fill="#F59EB5"/>
        </g>
    </svg>
);

export default WireFrameRenderIcon