import React from 'react';

const ResetIcon = () =>(
    <svg className={'old-svg'} width="45px" height="45px" viewBox="0 0 45 45" version="1.1">
        <g id="icon_reset" stroke="none" fill="none">
            <path d="M30.0625,15.4375 L33.1875,12.3125 L33.1875,21.6875 L23.8125,21.6875 L28.125,17.375 C26.5416587,15.7916587 24.6666775,15 22.5,15 C20.2916556,15 18.4062578,15.7812422 16.84375,17.34375 C15.2812422,18.9062578 14.5,20.7916556 14.5,23 C14.5,25.2083444 15.2812422,27.0937422 16.84375,28.65625 C18.4062578,30.2187578 20.2916556,31 22.5,31 C24.2500087,31 25.8124931,30.5104216 27.1875,29.53125 C28.5625069,28.5520784 29.5208306,27.2708413 30.0625,25.6875 L32.8125,25.6875 C32.2291637,28.020845 30.9791762,29.9374925 29.0625,31.4375 C27.1458237,32.9375075 24.9583456,33.6875 22.5,33.6875 C19.5833188,33.6875 17.0833438,32.6458437 15,30.5625 C12.9166562,28.4791562 11.875,25.9583481 11.875,23 C11.875,20.0416519 12.9166562,17.5208437 15,15.4375 C17.0833438,13.3541562 19.5833188,12.3125 22.5,12.3125 C25.4583481,12.3125 27.9791563,13.3541562 30.0625,15.4375 Z" id="border_left" fill="#0F9DDD"/>
        </g>
    </svg>
);

export default ResetIcon