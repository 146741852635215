import React from 'react';

const VertexCloudRenderIcon = () =>(
    <svg width="37px" height="37px" viewBox="0 0 37 37" version="1.1">
        <g id="icon-vertex-cloud" stroke="none" fill="none">
            <path d="M5.33486239,9.15921469 C5.33486239,10.3156555 4.36449541,11.2530922 3.16743119,11.2530922 C1.97036697,11.2530922 1,10.3156555 1,9.15921469 C1,8.00277388 1.97036697,7.06533714 3.16743119,7.06533714 C4.36449541,7.06533714 5.33486239,8.00277388 5.33486239,9.15921469" id="Fill-24" fill="#F49DB4"/>
            <path d="M20.5872523,16.6042057 C20.5872523,17.7606465 19.6168853,18.6980833 18.4198211,18.6980833 C17.2227569,18.6980833 16.2523899,17.7606465 16.2523899,16.6042057 C16.2523899,15.4477649 17.2227569,14.5103282 18.4198211,14.5103282 C19.6168853,14.5103282 20.5872523,15.4477649 20.5872523,16.6042057" id="Fill-26" fill="#F49DB4"/>
            <path d="M5.97706422,26.6857453 C5.97706422,27.8421861 5.00669725,28.7796229 3.80963303,28.7796229 C2.61256881,28.7796229 1.64220183,27.8421861 1.64220183,26.6857453 C1.64220183,25.5293045 2.61256881,24.5918678 3.80963303,24.5918678 C5.00669725,24.5918678 5.97706422,25.5293045 5.97706422,26.6857453" id="Fill-28" fill="#F49DB4"/>
            <path d="M20.3348624,2.09387755 C20.3348624,3.25031837 19.3644954,4.1877551 18.1674312,4.1877551 C16.970367,4.1877551 16,3.25031837 16,2.09387755 C16,0.937436735 16.970367,0 18.1674312,0 C19.3644954,0 20.3348624,0.937436735 20.3348624,2.09387755" id="Fill-30" fill="#F49DB4"/>
            <path d="M35.8395459,9.4693102 C35.8395459,10.625751 34.8691789,11.5631878 33.6721147,11.5631878 C32.4750505,11.5631878 31.5046835,10.625751 31.5046835,9.4693102 C31.5046835,8.31286939 32.4750505,7.37543265 33.6721147,7.37543265 C34.8691789,7.37543265 35.8395459,8.31286939 35.8395459,9.4693102" id="Fill-32" fill="#F49DB4"/>
            <path d="M20.4261078,34.7603265 C20.4261078,35.9167673 19.4557408,36.8542041 18.2586766,36.8542041 C17.0616124,36.8542041 16.0912454,35.9167673 16.0912454,34.7603265 C16.0912454,33.6038857 17.0616124,32.666449 18.2586766,32.666449 C19.4557408,32.666449 20.4261078,33.6038857 20.4261078,34.7603265" id="Fill-34" fill="#F49DB4"/>
            <path d="M35.9995986,26.3849094 C35.9995986,27.5413502 35.0292317,28.4787869 33.8321674,28.4787869 C32.6351032,28.4787869 31.6647362,27.5413502 31.6647362,26.3849094 C31.6647362,25.2284686 32.6351032,24.2910318 33.8321674,24.2910318 C35.0292317,24.2910318 35.9995986,25.2284686 35.9995986,26.3849094" id="Fill-36" fill="#F49DB4"/>
        </g>
    </svg>
);

export default VertexCloudRenderIcon